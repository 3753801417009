export const environment = {
  production: false,
  entityType: 'IP',
  urlDEV: 'https://api-staging-ip.certikap.com/api/v1',
  urlDEV_images: 'https://api-staging-ip.certikap.com/uploads/users/',
  urlDownload: 'https://api-staging-ip.certikap.com/sources/public/uploads/generated-csv/cron/exports/',
  urlDownloadCertification: 'https://api-staging-ip.certikap.com/uploads/generated-csv/certifications/',
  formURL: 'https://staging-ip.certikap.com/v1/form/auto-inscription/',
  ELMS_url: 'https://pasteur.elmg.net/',
};


